import { Text, Heading } from 'grommet'
import styled from 'styled-components'

export const responsiveText = (min, max) =>
  `font-size: calc(${min}px + (${max} - ${min}) * ((100vw - 320px) / (2160 - 320)));
   line-height: calc((${min}px + (${max} - ${min}) * ((100vw - 320px) / (2160 - 320))) + 0.2em);
   @media(min-width:2160px){
    font-size:${max}px;
   }
   @media(max-width:320px){
    font-size:${min}px;
   }`

export const fixWidows = text => ({
  __html: text.replace(/ (?=[^ ]*$)/i, '\u00a0'),
})

export const MonoText = styled(Text)`
  font-family: 'Share Tech Mono', monospaced;
  text-transform: uppercase;
  letter-spacing: 0.025em;
`

export const MonoHeading = styled(Heading)`
  font-family: 'Share Tech Mono', monospaced;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: normal;
`
